define("ember-intl/adapters/default", ["exports", "ember-intl/models/translation"], function (_exports, _translation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */
  var _default = Ember.Object.extend({
    _seen: null,

    /** @private **/
    locales: Ember.computed('_seen.[]', function () {
      return Ember.get(this, '_seen').map(function (l) {
        return l.localeName;
      });
    }).readOnly(),

    /** @private **/
    init: function init() {
      this._super();

      this._seen = Ember.A();
    },

    /** @private **/
    lookupLocale: function lookupLocale(localeName) {
      return this._seen.findBy('localeName', localeName);
    },

    /** @private **/
    localeFactory: function localeFactory(localeName) {
      var owner = Ember.getOwner(this);
      var lookupName = "ember-intl@translation:".concat(localeName);
      var model = owner.lookup(lookupName);

      if (model) {
        return model;
      }

      var Klass;

      if (owner.hasRegistration('model:ember-intl-translation')) {
        Klass = owner.factoryFor('model:ember-intl-translation').class;
      } else {
        Klass = _translation.default;
      }

      var ModelKlass = Klass.extend();
      Object.defineProperty(ModelKlass.proto(), 'localeName', {
        writable: false,
        enumerable: true,
        value: localeName
      });
      owner.register(lookupName, ModelKlass);
      model = owner.lookup(lookupName);

      this._seen.pushObject(model);

      return model;
    },

    /** @private **/
    has: function has(localeName, translationKey) {
      var model = this.lookupLocale(localeName);
      return model && model.has(translationKey);
    },

    /** @private **/
    lookup: function lookup(localeName, translationKey) {
      var model = this.lookupLocale(localeName);

      if (model && model.has(translationKey)) {
        return model.getValue(translationKey);
      }
    }
  });

  _exports.default = _default;
});