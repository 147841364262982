define("ember-macro-helpers/create-class-computed", ["exports", "ember-macro-helpers/get-value", "ember-macro-helpers/collapse-keys", "ember-macro-helpers/flatten-keys", "ember-macro-helpers/-constants"], function (_exports, _getValue, _collapseKeys, _flattenKeys, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _Ember = Ember,
      WeakMap = _Ember.WeakMap;
  var PROPERTIES = new WeakMap();

  function findOrCreatePropertyInstance(context, propertyClass, key, cp) {
    var propertiesForContext = PROPERTIES.get(context);

    if (!propertiesForContext) {
      propertiesForContext = new WeakMap();
      PROPERTIES.set(context, propertiesForContext);
    }

    var property = propertiesForContext.get(cp);

    if (property) {
      return property;
    } // let owner = getOwner(context);


    property = propertyClass.create(
    /* owner.ownerInjection(), */
    {
      key: key,
      context: context,
      nonStrings: Ember.Object.create()
    });
    propertiesForContext.set(cp, property);

    if (context instanceof Ember.Component) {
      context.one('willDestroyElement', function () {
        property.destroy();
      });
    }

    return property;
  }

  var BaseClass = Ember.Object.extend({
    computedDidChange: Ember.observer('computed', function () {
      var context = this.context,
          key = this.key,
          preventDoubleRender = this.preventDoubleRender;

      if (context.isDestroying) {
        // controllers can get into this state
        this.destroy();
        return;
      }

      if (preventDoubleRender) {
        return;
      }

      context.notifyPropertyChange(key);
    })
  });

  function resolveMappedLocation(key, i) {
    if (typeof key === 'string') {
      return "context.".concat(key);
    } else {
      return "nonStrings.".concat(i);
    }
  }

  function _default(observerBools, macroGenerator) {
    return function () {
      for (var _len = arguments.length, keys = new Array(_len), _key = 0; _key < _len; _key++) {
        keys[_key] = arguments[_key];
      }

      var _collapseKeysWithMap = (0, _collapseKeys.collapseKeysWithMap)(keys),
          collapsedKeys = _collapseKeysWithMap.collapsedKeys,
          keyMap = _collapseKeysWithMap.keyMap;

      function getOriginalArrayDecorator(key, i) {
        if (typeof key === 'string') {
          var originalKey = keys[keyMap[i]];

          if (originalKey.indexOf(_constants.ARRAY_EACH) !== -1 || originalKey.indexOf(_constants.ARRAY_LENGTH) !== -1) {
            return originalKey;
          }
        }

        return key;
      }

      var mappedKeys = [];

      function rewriteComputed(obj, key) {
        var _this = this;

        var mappedWithResolvedOberverKeys = mappedKeys.map(function (macro, i) {
          var shouldObserve = observerBools[i];

          if (shouldObserve) {
            macro = (0, _getValue.default)({
              context: _this,
              macro: macro,
              key: key
            });
          }

          return macro;
        });
        var cp = macroGenerator.apply(this, mappedWithResolvedOberverKeys);
        Ember.defineProperty(this, 'computed', cp);
      }

      var classProperties = {};
      collapsedKeys.forEach(function (key, i) {
        var shouldObserve = observerBools[i];

        if (!shouldObserve) {
          key = getOriginalArrayDecorator(key, i);
        }

        var mappedKey = resolveMappedLocation(key, i);
        mappedKeys.push(mappedKey);

        if (shouldObserve) {
          classProperties["key".concat(i, "DidChange")] = Ember.observer(mappedKey, rewriteComputed);
        }
      });
      var ObserverClass = BaseClass.extend(classProperties, {
        // can't use rewriteComputed directly, maybe a bug
        // https://github.com/emberjs/ember.js/issues/15246
        onInit: Ember.on('init', function () {
          rewriteComputed.call(this);
        })
      });
      var cp = Ember.computed.apply(void 0, _toConsumableArray((0, _flattenKeys.default)(keys)).concat([function (key) {
        var _this2 = this;

        var propertyInstance = findOrCreatePropertyInstance(this, ObserverClass, key, cp);
        var properties = collapsedKeys.reduce(function (properties, macro, i) {
          if (typeof macro !== 'string') {
            properties[i.toString()] = (0, _getValue.default)({
              context: _this2,
              macro: macro,
              key: key
            });
          }

          return properties;
        }, {}); // eslint-disable-next-line ember/no-side-effects

        Ember.set(propertyInstance, 'preventDoubleRender', true);
        Ember.setProperties(propertyInstance.nonStrings, properties); // eslint-disable-next-line ember/no-side-effects

        Ember.set(propertyInstance, 'preventDoubleRender', false);
        return Ember.get(propertyInstance, 'computed');
      }])).readOnly();
      return cp;
    };
  }
});