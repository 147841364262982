define("ember-fullscreen/services/fullscreen", ["exports", "screenfull"], function (_exports, _screenfull) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    screenfull: _screenfull.default,
    init: function init() {
      this._super.apply(this, arguments);

      this.setupListeners();
    },
    setupListeners: function setupListeners() {
      if (this.get('isAvailable')) {
        this.updateEnabled();
        this.handler = Ember.run.bind(this, this.updateEnabled);
        this.errorHandler = Ember.run.bind(this, this.onError);
        this.screenfull.on('change', this.handler);
        this.screenfull.on('error', this.errorHandler);
      }
    },
    willDestroy: function willDestroy() {
      if (this.get('isAvailable')) {
        this.screenfull.off('change', this.handler);
        this.screenfull.off('error', this.errorHandler);
      }
    },
    isEnabled: false,
    isAvailable: Ember.computed(function () {
      return this.screenfull && this.screenfull.enabled;
    }),
    updateEnabled: function updateEnabled() {
      var isFullscreenEnabled = this.screenfull.isFullscreen;
      this.set('isEnabled', isFullscreenEnabled);
      this.trigger('fullscreenChange', isFullscreenEnabled);
    },
    onError: function onError(event) {
      this.trigger('error', event);
    },
    enable: function enable(elem) {
      this.screenfull && this.screenfull.request(elem);
    },
    disable: function disable() {
      this.screenfull && this.screenfull.exit();
    },
    toggle: function toggle(elem) {
      this.screenfull && this.screenfull.toggle(elem);
    }
  });

  _exports.default = _default;
});